<template>
  <OverviewWrapper>
    <AppCard :title="$t('All Invoices')" title-actions>
      <template #titleActions>
        <div class="d-flex flex-column flex-md-row align-end align-md-center justify-end">
          <v-text-field
            v-model="search"
            class="mb-4 mb-md-0 mr-md-4"
            outlined
            hide-details="auto"
            dense
            type="text"
            label="Search Invoice"
            style="max-width: 200px"
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn to="Invoices/create" color="secondary" rounded dark>
            <v-icon>mdi-plus</v-icon>
            <span class="d-none d-sm-inline">Invoice erstellen</span>
          </v-btn>
        </div>
      </template>

      <AppPagination v-if="pagination" :pagination="pagination" location="top" />

      <v-data-table
        class="v-data-table__overview"
        :headers="headers"
        :items="invoices"
        :page="pagination.page"
        :items-per-page="pagination.perPage"
        :loading="pending"
        hide-default-footer
        :item-class="item => (['closed', 'storno', 'cancled'].includes(item.status) ? highlightClasses : '')"
      >
        <template #[`item.client`]="{ item }">
          <router-link v-if="item.client_id" :to="{ name: 'clients-item', params: { id: item.client_id } }">
            <b>{{ item.client.contact_handle.organisation }}</b>
            {{ item.client.contact_handle.firstname }}
            {{ item.client.contact_handle.lastname }}
          </router-link>
        </template>

        <template #[`item.billingdate`]="{ item }">
          {{ item.billingdate | moment('DD.MM.YYYY') }}
        </template>

        <template #[`item.due_at`]="{ item }">
          {{ item.billingdate | moment('add', '14 days', 'DD.MM.YYYY') }}
        </template>

        <template #[`item.vkp`]="{ item }">{{ $n(item.vkp, 'currency', 'de-DE') }}</template>

        <template #[`item.status`]="{ item }">
          <InvoiceRowStateButtons
            :invoice="item"
            :pending-invoices="pendingInvoices"
            :disabled="pending"
            @onChangeState="changeState(item.id, ...arguments)"
          />
        </template>

        <template #[`item.actions`]="{ item }">
          <InvoiceRowActions
            :invoice="item"
            :pending-invoices="pendingInvoices"
            @onRemove="remove"
            @onDownload="download(item)"
          />
        </template>
      </v-data-table>

      <AppPagination v-if="pagination" :pagination="pagination" />
    </AppCard>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, reactive, ref, watch, computed } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import AppPagination from '@/components/UI/AppPagination'
import InvoiceRowActions from '@/components/Invoices/InvoiceRowActions'
import InvoiceRowStateButtons from '@/components/Invoices/InvoiceRowStateButtons'
import OverviewWrapper from '@/layouts/OverviewWrapper'

export default defineComponent({
  name: 'ViewInvoices',
  components: {
    AppCard,
    AppPagination,
    InvoiceRowActions,
    InvoiceRowStateButtons,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $vuetify, $router }, root }) {
    const page = ref(1)
    const search = ref(null)
    const pendingInvoices = reactive([])
    const headers = [
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Invoice Nr.',
        value: 'invoice_nr',
      },
      {
        sortable: false,
        text: 'Billingdate',
        value: 'billingdate',
      },
      {
        sortable: false,
        text: 'Amount',
        value: 'vkp',
      },
      {
        sortable: false,
        text: 'Due at',
        value: 'due_at',
      },
      {
        sortable: false,
        text: 'Status',
        value: 'status',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
        width: '165px',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const invoices = computed(_ => $store.state.invoices.invoices)
    const pagination = computed(_ => $store.state.invoices.pagination)
    const pending = computed(_ => $store.state.invoices.pending)
    const highlightClasses = computed(_ => {
      return $vuetify.theme.dark ? 'grey darken-4 text--disabled' : 'grey lighten-4 text--disabled'
    })
    const getInvoices = payload => $store.dispatch('invoices/getInvoices', payload)
    const setStatus = status => $store.dispatch('invoices/setStatus', status)
    const deleteInvoice = invoiceId => $store.dispatch('invoices/deleteInvoice', invoiceId)
    const getInvoicePdf = invoiceId => $store.dispatch('invoice/getInvoicePdf', invoiceId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getInvoices({ page: page.value })

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      clearTimeout(delayTimer)
      getInvoicesWithParams()
    })

    const delayTimer = ref(null)
    watch(search, _ => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        page.value = 1
        getInvoicesWithParams()
      }, 500)
    })

    const getInvoicesWithParams = _ => {
      const params = {
        page: page.value,
      }

      if (search.value) {
        params.search = search.value
      }

      getInvoices(params)
    }

    const changeState = (id, status) => {
      const invoice = {
        id,
        status,
      }

      const pendingButtonIndex = pendingInvoices.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingInvoices.push(id)

      setStatus(invoice)
        .then(response => {
          pendingInvoices.splice(pendingButtonIndex, 1)

          addToast({
            msg: 'Status erfolgreich geändert',
            type: 'info',
          })

          getInvoicesWithParams()
        })
        .catch(error => {
          addToast({
            msg: 'Statusänderung fehlgeschlagen',
            type: 'error',
          })
          pendingInvoices.splice(pendingButtonIndex, 1)
        })
    }

    const remove = id => {
      const pendingButtonIndex = pendingInvoices.indexOf(id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      if (confirm('Sicher, dass die Rechnung gelöscht werden soll?')) {
        pendingInvoices.push(id)

        deleteInvoice(id)
          .then(response => {
            pendingInvoices.splice(pendingButtonIndex, 1)

            addToast({
              msg: 'Rechnung erfolgreich gelöscht',
              type: 'success',
            })

            if (pagination.value.current === 1 && page.value !== 1) {
              $router.push({ query: { page: page.value - 1 } })
            } else {
              getInvoicesWithParams()
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
            pendingInvoices.splice(pendingButtonIndex, 1)
          })
      }
    }

    const download = invoice => {
      const pendingButtonIndex = pendingInvoices.indexOf(invoice.id)
      if (pendingButtonIndex !== -1 && pending.value) {
        return
      }

      pendingInvoices.push(invoice.id)

      getInvoicePdf(invoice.id)
        .then(invoicePdf => {
          pendingInvoices.splice(pendingButtonIndex, 1)
          const link = document.createElement('a')
          link.download = `${invoice.invoice_nr || 'rechnung_entwurf'}.pdf`
          link.href = 'data:application/octet-stream;base64,' + invoicePdf
          link.click()
        })
        .catch(error => {
          addToast({
            msg: 'Download fehlgeschlagen',
            type: 'error',
          })
          pendingInvoices.splice(pendingButtonIndex, 1)
        })
    }

    return {
      page,
      search,
      pendingInvoices,
      headers,
      invoices,
      pagination,
      pending,
      highlightClasses,
      changeState,
      remove,
      download,
    }
  },
}) //
</script>


<style lang="scss" scoped>
.table-responsive {
  overflow: visible;
}
</style>
